// extracted by mini-css-extract-plugin
export var banner = "fiftyFiftyBanner-module--banner--4phXG";
export var banner__background = "fiftyFiftyBanner-module--banner__background--xeuRm";
export var banner__body = "fiftyFiftyBanner-module--banner__body--4Dg9T";
export var banner__content = "fiftyFiftyBanner-module--banner__content--s+K0C";
export var banner__cta = "fiftyFiftyBanner-module--banner__cta--6K2LS";
export var banner__darken = "fiftyFiftyBanner-module--banner__darken--OPGFD";
export var banner__footer = "fiftyFiftyBanner-module--banner__footer--KV0VW";
export var banner__gradient = "fiftyFiftyBanner-module--banner__gradient--EKWjg";
export var banner__header = "fiftyFiftyBanner-module--banner__header--0DdRx";
export var banner__headerContent = "fiftyFiftyBanner-module--banner__headerContent--SxA28";
export var banner__loader = "fiftyFiftyBanner-module--banner__loader--IX92q";
export var banner__wrapper = "fiftyFiftyBanner-module--banner__wrapper--bI5ut";